import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
import physical from './images/global_physical.jpg';
import atmoAndIso from './images/atmospheric_pressure_and_isotherms.jpg';
import precAndEvap from './images/precipitation_and_evaporation.jpg';
import climate from './images/climate_zones.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Map = makeShortcode("Map");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Naos is the native name for the entire planet. Naos is an Earth-like world, hospitable for most forms of life. There are four major continents on the planet of Naos: `}<a parentName="p" {...{
        "href": "/Agios",
        "title": "Agios"
      }}>{`Agios`}</a>{`, `}<a parentName="p" {...{
        "href": "/Edesh",
        "title": "Edesh"
      }}>{`Edesh`}</a>{`, `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Idus",
        "title": "Idus"
      }}>{`Idus`}</a>{`.`}</p>
    <h2>{`Geography`}</h2>
    <p>{`Naos is a green world with Earth-like similarities, active plate tectonics.`}</p>

    <Map src={physical} width="600px" align="center" mdxType="Map" />
    <h2>{`Climate`}</h2>
    <h3>{`Atmospheric Pressure and Isotherms`}</h3>

    <Map src={atmoAndIso} width="600px" align="center" mdxType="Map" />
    <h3>{`Precipitation and Evaporation`}</h3>

    <Map src={precAndEvap} width="400px" align="center" mdxType="Map" />
    <h3>{`Climate Zones`}</h3>

    <Map src={climate} width="600px" align="center" mdxType="Map" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      